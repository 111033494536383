.uppy-Dashboard-AddFiles .uppy-DashboardTab,
.uppy-Dashboard-AddFiles .uppy-Dashboard-AddFiles-list span {
    border-bottom: 0 !important;
    margin: auto;
}

.uppy-Dashboard-AddFiles .uppy-DashboardTab button {
    height: 25vh;
    width: 25vw;
}

.uppy-Dashboard-AddFiles .uppy-Dashboard-AddFiles-title {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0;
    font-weight: 600 !important;
    line-height: 1.2 !important;
    color: #1a1a1a !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.25rem !important;
    font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    height: 5vh;
}

.uppy-Dashboard-AddFiles .uppy-Dashboard-AddFiles-list {
    flex: none !important;
    flex-direction: column-reverse;
}

.uppy-Dashboard-AddFiles {
    margin-left: 0 !important;
    margin-right: 0 !important;
    height: auto !important;
    transform: translate(-50%, -50%) !important;
    left: 50%;
    top: 50%;
    border: 0 !important;
}

.uppy-Dashboard-AddFiles .uppy-DashboardTab button .uppy-DashboardTab-name {
    display: none;
}

.uppy-Dashboard-AddFiles .uppy-DashboardTab button svg {
    height: 25vh !important;
    width: 25vw !important;
    margin: 0 !important;
}

.uppy-Dashboard-inner {
    background-color: inherit !important;
    border: 0 !important;
}

.uppy-Dashboard-AddFiles button.uppy-DashboardTab-btn {
    padding: 0;
}

.uppy-Dashboard-dropFilesHereHint {
    position: relative !important;
    padding: 0 !important;
}

[data-uppy-acquirer-id=MyDevice] {
    display: none;
}