html,
body,
.root {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: none;
    letter-spacing: normal;
}

@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,80);

body {
    font-family: Montserrat, sans-serif;
}

.container {
    padding: 0;
    margin: 0;
}

.btn {
    border-radius: 10px 10px;
}